import Holidays from 'date-holidays';
import cloneDeep from 'lodash/cloneDeep';
import {
  ProjectInvoicedToDate,
  ProjectStage,
  Task,
} from '../network/models/AppModels';
import {
  TABLE_FILTERED_PROPERTIES,
  TABLE_FIRST_SELECTED_PROPERTY,
  TABLE_SORT_VALUES,
} from './Constants';

class HelperFunctions {
  static formatTimeStamp(date: any) {
    const options: any = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat(navigator.language, options)
      .format(new Date(date))
      .toString();
  }

  static formatTimeStampToLocale(date: any) {
    const options: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(date)?.toLocaleString(navigator.language, options);
  }

  static formatTimeStampLong(date: any) {
    const options: any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Intl.DateTimeFormat(navigator.language, options)
      .format(new Date(date))
      .toString();
  }

  static formatDate(date: any) {
    const timestamp = Date.parse(date);
    if (isNaN(timestamp)) {
      return date;
    }
    const currentDate = new Date(date);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Zero-padding for month
    const day = currentDate.getDate().toString().padStart(2, '0'); // Zero-padding for day
    const year = currentDate.getFullYear().toString().slice(-2); // Last two digits of the year

    return `${month}/${day}/${year}`;
  }

  static formatDateTime(date: any) {
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Specify 12-hour clock format
    };
    return new Date(date)?.toLocaleString(navigator.language, options);
  }

  static formatNumber(number: number, isDecimal = false) {
    let formattedNumber: string;
    if (isDecimal) {
      // Use Intl.NumberFormat for comma separators with up to two decimal places
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2, // Allow up to two decimal places
      });
      if (number === null || number === undefined || number.toString() === '') {
        formattedNumber = '';
      } else {
        formattedNumber = formatter.format(number);
      }
    } else {
      // Round the number to the nearest integer and format with comma separators
      const roundedNumber = Math.round(number);
      formattedNumber = new Intl.NumberFormat('en-US').format(roundedNumber);
    }
    return formattedNumber;
  }

  static columnSortCompare = (a: string, b: string) => {
    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  static formatUnderScoreString = (
    propertyName: string,
    disableFormatter = false
  ) => {
    if (disableFormatter) return propertyName;
    let finalString = propertyName;
    if (propertyName?.includes('_')) {
      finalString = propertyName
        ?.split('_') // Split by underscores
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        ?.join(' '); // Join words with spaces
    }
    if (finalString?.includes('.')) {
      const propertyNameSplit = finalString?.split(' ');
      finalString = finalString
        ?.split('.') // Split by dot
        ?.slice(propertyNameSplit?.length > 1 ? -1 : -2)
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        ?.join(' '); // Join words with spaces
    }
    if (finalString?.includes('-')) {
      const propertyNameSplit = finalString?.split(' ');
      finalString = finalString
        ?.split('-') // Split by hyphen
        // ?.slice(propertyNameSplit?.length > 1 ? -1 : -2)
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        ?.join(' '); // Join words with spaces
    }
    return finalString?.charAt(0)?.toUpperCase() + finalString?.slice(1);
  };
  static formatCurrency = (number: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(number);
  };

  static objectsAreEqual = (a: object, b: object) =>
    JSON.stringify(a) === JSON.stringify(b);

  static movePropertyToFirst(obj: any, prop: string) {
    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      return obj; // Property does not exist in object
    }

    // Create a new object with the property moved to the first position
    const newObj = { [prop]: obj[prop] };
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && key !== prop) {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  }

  static areArraysEqual(arr1: object[], arr2: object[]) {
    // Check if the arrays have the same length
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    // Iterate through the elements and compare them
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false; // Elements are not equal in the same position
      }
    }

    return true; // All elements are equal in the same order
  }

  static addBusinessDaysWithHolidays = (
    startDate: string,
    daysToAdd: number
  ) => {
    if (!startDate || daysToAdd < 1) {
      return null;
    }
    const currentDate = new Date(startDate);
    const holidays = new Holidays();
    if (process.env.REACT_APP_ENV === 'development') {
      holidays.init('KE');
    } else {
      holidays.init('US');
    }

    const isWeekend = (date: Date) => {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6; // 0 for Sunday, 6 for Saturday
    };

    const isHolidayOrWeekend = (date: Date) => {
      return isWeekend(date) || holidays.isHoliday(date);
    };

    let businessDaysAdded = 0;
    while (businessDaysAdded < daysToAdd) {
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
      // Skip weekends and holidays
      while (isHolidayOrWeekend(currentDate)) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      businessDaysAdded += 1;
    }

    return HelperFunctions.formatDate(currentDate?.toLocaleString());
  };

  static removeNullOrEmptyProperties(obj: any) {
    const filteredEntries = Object.entries(obj).filter(([key, value]) =>
      Array.isArray(value) ? value.length > 0 : Boolean(value)
    );
    return Object.fromEntries(filteredEntries);
  }

  // Helper function to get nested property values
  static getNestedPropertyValue = (obj: object, key: string) => {
    const keys = key.split('.');
    let nestedValue: any = obj;

    for (const nestedKey of keys) {
      if (
        nestedValue &&
        typeof nestedValue === 'object' &&
        nestedKey in nestedValue
      ) {
        nestedValue = nestedValue[nestedKey];
      } else {
        return ''; // Key not found or value is not an object
      }
    }

    return nestedValue;
  };
  static filterTableData = (originalData: any[], filteredPropertyData: any) => {
    if (Object.keys(filteredPropertyData)?.length > 0) {
      let newData = cloneDeep(originalData);
      newData = newData?.filter((item: any) => {
        if (item?.parent_task) {
          if (newData?.find((t: any) => t?.id === item?.parent_task)) {
            return true;
          }
        }
        return Object.keys(filteredPropertyData ?? {}).every((property) => {
          const stateKey = filteredPropertyData[property];
          const itemValue = HelperFunctions.getNestedPropertyValue(
            item,
            property
          );
          if (Array.isArray(itemValue)) {
            return (
              !stateKey ||
              stateKey.length === 0 ||
              (stateKey.length > 0 &&
                itemValue?.some((item: any) =>
                  stateKey.includes(item?.initials)
                )) //initials for mag team
            );
          } else {
            return (
              !stateKey ||
              stateKey.length === 0 ||
              (stateKey.length > 0 &&
                stateKey.includes(!itemValue ? 'Blank' : itemValue))
            );
          }
        });
      });

      return newData;
    } else {
      return originalData;
    }
  };

  static getSortedSingleArray = (
    order: string,
    customData: any[],
    isAlphanumeric = false
  ) => {
    if (!customData) {
      return [];
    }
    return customData.slice().sort((a: any, b: any) => {
      let valueA = a;
      let valueB = b;
      if (isAlphanumeric) {
        valueA = valueA?.split('-')[0];
        valueB = valueB?.split('-')[0];
      }
      // Convert string representations of decimal numbers to actual numbers
      const parseDecimal = (value: any) => {
        return typeof value === 'string' && /^\d+(\.\d+)?$/.test(value)
          ? parseFloat(value)
          : value;
      };

      valueA = parseDecimal(valueA);
      valueB = parseDecimal(valueB);

      // Check if the values are numbers
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      if (typeof valueA === 'number' && !valueB) {
        valueB = 0;
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
      if (!valueA && typeof valueB === 'number') {
        valueA = 0;
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      // If not numbers, convert to lowercase strings for alphabetical sorting
      const stringA = String(valueA).toLowerCase();
      const stringB = String(valueB).toLowerCase();
      return order === 'asc'
        ? stringA.localeCompare(stringB)
        : stringB.localeCompare(stringA);
    });
  };

  static getSortedArrayOfObjects = (
    order: string,
    customData: any[],
    property: string,
    isAlphanumeric = false
  ) => {
    if (!customData) {
      return [];
    }
    return customData?.slice()?.sort((a: any, b: any) => {
      let valueA = HelperFunctions.getNestedPropertyValue(a, property);
      let valueB = HelperFunctions.getNestedPropertyValue(b, property);
      if (property === 'stage.number' || isAlphanumeric) {
        valueA = valueA?.split('-')[0];
        valueB = valueB?.split('-')[0];
      }
      // Convert string representations of decimal numbers to actual numbers
      const parseDecimal = (value: any) => {
        return typeof value === 'string' && /^\d+(\.\d+)?$/.test(value)
          ? parseFloat(value)
          : value;
      };

      valueA = parseDecimal(valueA);
      valueB = parseDecimal(valueB);

      // Check for empty or null values
      if (!valueA && !valueB) return 0;
      if (!valueA) return 1; // Blank or null values are greater
      if (!valueB) return -1;

      // Check if the values are numbers
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      if (typeof valueA === 'number' && !valueB) {
        valueB = 0;
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
      if (!valueA && typeof valueB === 'number') {
        valueA = 0;
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      // If not numbers, convert to lowercase strings for alphabetical sorting
      const stringA = String(valueA).toLowerCase();
      const stringB = String(valueB).toLowerCase();
      return order === 'asc'
        ? stringA.localeCompare(stringB)
        : stringB.localeCompare(stringA);
    });
  };

  static findFirstNonEmptyKeyValue(obj: any) {
    for (const [key, value] of Object.entries(obj)) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        return { key, value };
      }
    }
    return null;
  }

  static filterSortTableData = (
    originalData: any[],
    filteredPropertyData: any,
    defaultSortValues: any,
    dataName: string,
    firstSelectedProperty: string
  ) => {
    //store user settings on update
    HelperFunctions.storeUserTableFilters(
      dataName,
      defaultSortValues,
      filteredPropertyData,
      firstSelectedProperty
    );

    let filteredData = HelperFunctions.filterTableData(
      originalData,
      filteredPropertyData
    );
    const propertyObj: any | null =
      HelperFunctions.findFirstNonEmptyKeyValue(defaultSortValues);
    if (propertyObj) {
      filteredData = HelperFunctions.getSortedArrayOfObjects(
        propertyObj?.value,
        filteredData,
        propertyObj?.key
      );
    }
    return filteredData;
  };

  static getTotalInvoicedToDate = (invoicedToDate: ProjectInvoicedToDate[]) => {
    return invoicedToDate?.reduce(
      (accumulator: number, currentValue: ProjectInvoicedToDate) => {
        return accumulator + Number(currentValue?.amount ?? 0);
      },
      0
    );
  };

  static generateUrlParams(paramsObject: any) {
    const urlParams: URLSearchParams = new URLSearchParams();

    // Iterate over the object's entries
    for (const [key, value] of Object.entries(paramsObject)) {
      // Check if the value is not null or undefined
      if (value !== null && value !== undefined) {
        // Add the key-value pair to the URLSearchParams
        urlParams.append(key, value as string);
      }
    }

    // Get the final string of URL parameters
    const paramsString = urlParams.toString();

    return paramsString.length > 0 ? `?${paramsString}` : '';
  }

  static objectToCommaQueryString(obj: any) {
    const cleanObj = HelperFunctions.removeNullOrEmptyProperties(obj);
    return Object.entries(cleanObj)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          let queryString: string = '';
          value?.forEach((val: string | number) => {
            if (typeof val === 'string') {
              // queryString += `${key}=${val.replaceAll(', ', ',')}&`;
              queryString += `${encodeURIComponent(key)}=${encodeURIComponent(val.replaceAll(', ', ','))}&`;
            } else {
              // queryString += `${key}=${val}&`;
              queryString += `${encodeURIComponent(key)}=${encodeURIComponent(String(val))}&`;
            }
          });
          // return queryString;
          return queryString;
        } else {
          // return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
        }
      })
      .join('&');
  }

  static getHighestStage(stagesObj: any) {
    let highestStage = '';
    const stages: ProjectStage[] = HelperFunctions.getSortedArrayOfObjects(
      'desc',
      Object.values(stagesObj),
      'name',
      true
    );
    if (stages?.length === 12) {
      const cmpStages = stages?.filter(
        (stage: ProjectStage) => stage.status === 'CMP'
      );
      if (cmpStages?.length === stages?.length) {
        highestStage = 'Closed';
      } else if (
        cmpStages?.length > 0 &&
        cmpStages?.length ===
          stages?.length -
            stages?.filter((stage: ProjectStage) => stage.status === '')?.length
      ) {
        const cmpIndex = stages?.findIndex(
          (stage: ProjectStage) => stage.status === 'CMP'
        );
        if (cmpIndex != -1) {
          highestStage = stages[cmpIndex - 1]?.name ?? '';
        }
      } else if (
        stages?.filter((stage: ProjectStage) => stage.status === '')?.length ===
        stages?.length
      ) {
        highestStage = stages[11]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'CLST')
            ?.status as string
        )
      ) {
        highestStage = stages[0]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'INST')
            ?.status as string
        )
      ) {
        highestStage = stages[1]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'PRIN')
            ?.status as string
        )
      ) {
        highestStage = stages[2]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'CNTR')
            ?.status as string
        )
      ) {
        highestStage = stages[3]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'PRPL')
            ?.status as string
        )
      ) {
        highestStage = stages[4]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'IGAD')
            ?.status as string
        )
      ) {
        highestStage = stages[5]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'PRPR')
            ?.status as string
        )
      ) {
        highestStage = stages[6]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'ESTM')
            ?.status as string
        )
      ) {
        highestStage = stages[7]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'DVMNT')
            ?.status as string
        )
      ) {
        highestStage = stages[8]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'SRVY')
            ?.status as string
        )
      ) {
        highestStage = stages[9]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'PRDV')
            ?.status as string
        )
      ) {
        highestStage = stages[10]?.name ?? '';
      } else if (
        ['WRK', 'STK'].includes(
          stages?.find((stage: ProjectStage) => stage.code === 'PRSP')
            ?.status as string
        )
      ) {
        highestStage = stages[11]?.name ?? '';
      }
    }
    return highestStage;
  }

  static storeUserTableFilters(
    dataName: string,
    defaultSortValues: any,
    filteredPropertyData: any,
    firstSelectedProperty: string
  ) {
    localStorage[`${dataName}${TABLE_SORT_VALUES}`] =
      JSON.stringify(defaultSortValues);
    localStorage[`${dataName}${TABLE_FILTERED_PROPERTIES}`] =
      JSON.stringify(filteredPropertyData);
    localStorage[`${dataName}${TABLE_FIRST_SELECTED_PROPERTY}`] =
      firstSelectedProperty;
  }

  static countAvailableColumnfilters = (
    property: string,
    dataName: string,
    defaultSortValues: any,
    filteredPropertyData: any,
    firstSelectedProperty: string
  ) => {
    //store user settings on update
    HelperFunctions.storeUserTableFilters(
      dataName,
      defaultSortValues,
      filteredPropertyData,
      firstSelectedProperty
    );

    const keywordsFilter =
      Object.values(filteredPropertyData[property] ?? {})?.length ?? 0;
    const sortOrderFilters = defaultSortValues[property] ? 1 : 0;
    return sortOrderFilters + keywordsFilter;
  };

  static orderColumns(
    columns: any[],
    colsOrders: any,
    updateColumns: any[],
    dataName: string
  ) {
    let workingColumns = updateColumns;

    if (colsOrders && Object.keys(colsOrders ?? {})?.length > 0) {
      if (
        dataName?.toLowerCase()?.includes('project') ||
        dataName?.toLowerCase()?.includes('comment') ||
        dataName?.toLowerCase()?.includes('summary')
      ) {
        let updateColumnsFlat = workingColumns.flatMap((col, index) => [
          col,
          ...(col.children ?? []),
        ]);
        updateColumnsFlat = colsOrders?.map((index: number) => {
          const col = updateColumnsFlat[index];

          if (col?.children?.length > 0) {
            const childColOrders = colsOrders?.slice(
              index + 1,
              col?.children?.length + index + 1
            );

            return {
              ...col,
              children: childColOrders?.map(
                (cIndex: number) => updateColumnsFlat[cIndex]
              ),
            };
          } else {
            return col;
          }
        });
        const columnsKeys = columns?.map((item: any) => item?.key);
        const unflatten = (flatArray: any[]) => {
          const result = flatArray.reduce(
            (acc: any, item: any, index: number) => {
              if (columnsKeys.includes(item?.key)) {
                acc.push(item);
              }
              return acc;
            },
            []
          );
          return result;
        };
        workingColumns = unflatten(updateColumnsFlat);
      } else {
        workingColumns = colsOrders?.map(
          (index: number) => workingColumns[index]
        );
      }
    }
    return workingColumns;
  }

  static isUUID(str: string) {
    // UUID pattern using regular expression
    const uuidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    // Check if the string matches the UUID pattern
    return uuidPattern.test(str);
  }

  static doesNewRecordMeetFilters(filterProperties: any, fieldsAndValues: any) {
    const filters = cloneDeep(filterProperties);
    let filterMatch = true;
    Object.keys(filters ?? {}).forEach((field) => {
      if (filters[field] !== '' && filters[field]?.length !== 0) {
        let value = fieldsAndValues[field];
        value = HelperFunctions.getNestedPropertyValue(fieldsAndValues, field);
        if (value === '') {
          value = 'Blank';
        }

        if (Array.isArray(value) && field === 'team') {
          value = value?.map((val: any) => val?.initials);
          if (!value?.some((item: string) => filters[field]?.includes(item))) {
            filterMatch = false;
            return; //exit loop
          }
        } else {
          if (
            !filters[field].includes(value) &&
            !(
              value &&
              filters[field].includes('Not Blank') &&
              filters[field]?.length === 1
            )
          ) {
            filterMatch = false;
            return; //exit loop
          }
        }
      }
    });
    return filterMatch;
  }

  static generateRandomString(length: number) {
    // Define the character set for generating the random string
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Use Uint32Array for cryptographically secure random numbers
    const randomValues = new Uint32Array(length);
    crypto.getRandomValues(randomValues);

    let result = '';
    for (let i = 0; i < length; i++) {
      // Use the random values to get random indices
      const randomIndex = randomValues[i] % characters.length;
      // Append the character at the random index to the result string
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  static addNewCommentToCache(row: any, oldData: any, model_or_error: any) {
    if (row?.parent_task) {
      return {
        pages: (oldData?.pages ?? []).map((page: any) => ({
          ...page,
          data: (page?.data ?? []).map((item: any) =>
            item?.id === row?.parent_task
              ? {
                  ...item,
                  sub_tasks: item.sub_tasks?.map((subTask: Task) =>
                    subTask?.id === row?.id
                      ? {
                          ...subTask,
                          comments: [
                            ...(subTask.comments ?? []),
                            model_or_error,
                          ],
                        }
                      : subTask
                  ),
                }
              : item
          ),
        })),
        pageParams: oldData?.pageParams,
      };
    } else {
      return {
        pages: (oldData?.pages ?? []).map((page: any) => ({
          ...page,
          data: (page?.data ?? []).map((item: any) =>
            item?.id === row?.id
              ? {
                  ...item,
                  comments: [...item.comments, model_or_error],
                }
              : item
          ),
        })),
        pageParams: oldData?.pageParams,
      };
    }
  }

  static extractEmails(value: string) {
    const text = value ? value?.toLowerCase() : '';
    // Regular expression to match email addresses
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Find all matches in the text
    const emails = text.match(emailRegex);

    // Return the array of emails or an empty array if no matches are found
    return emails ? emails : [];
  }

  static getInitialsFromName(name: string) {
    const parts = name?.trim()?.toUpperCase()?.split(' ');
    if (parts?.length > 1 && parts[1] !== ' ') {
      return `${parts[0][0]}${parts[1][0]}`;
    }
    return name?.charAt(0);
  }
}

export default HelperFunctions;
