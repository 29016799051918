import { createContext, useState } from 'react';
import { AuthActions } from '../network/AuthActions';
import { User } from '../network/models/AccountModels';

interface AppContextInterface {
  user: User;
}

const AppStateContext = createContext<any>({});

const AppContextProvider = (props: any) => {
  const { children } = props;
  const accountActions: any = new AuthActions();
  const [globalContentState, setGlobalContentState] =
    useState<AppContextInterface>({
      user: accountActions.retrieveAuthenticatedUser(),
    });
  return (
    <AppStateContext.Provider
      value={{ globalContentState, setGlobalContentState }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export { AppContextProvider, AppStateContext };
