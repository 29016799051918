import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';
import ThemeCustomization from './themes';
import 'react-data-grid/lib/styles.css';

function App() {
  return (
    <ThemeCustomization>
      <SnackbarProvider maxSnack={3}>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </SnackbarProvider>
    </ThemeCustomization>
  );
}

export default App;
